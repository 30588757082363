import React, { Fragment, useMemo } from 'react'
import { H1 } from '~/styled/components/typography'
import { Wrapper, ProductTitle, InfoList } from './styles'
import { useProductDetail } from '../Provider'
import { Box } from 'theme-ui'
import { space } from '~/gatsby-plugin-theme-ui'
import Parameters from '~/components/parameters'
import CustomQuickShipArt from '~/components/custom-quick-ship-art'
import CustomQuickShip from '~/components/custom-quick-ship'
import PreOrderNote from '~/components/product-detail-panel/pre-order-note'
import { FlyOutProvider } from '~/containers/fly-out/contex'
import FlyOut from '~/containers/fly-out'
import { AddToCartButton } from '~/components/add-to-card-button'
import { ProductLinks } from '../product-links'
import { OrderSwatchProps } from '~/components/product-detail-panel/order-swatch'
import { AntiqueRugsInfos } from '~/components/antique-rugs-infos'

import { AddFavoriteButton } from '~/componentsV2/addFavoriteBtn'
import ProductPriceContainer from '../product-price'
import { AffirmSnippet } from '~/componentsV2/affirm'
import { RugsCustomSize } from '~/componentsV2/rug-custom-size'

const ProductDetailPanel: React.FC = () => {
  const {
    product,
    productVariant,
    productPrice,
    productUtils: { isAntiqueRug, isCustomQuickshipArt, isUpholstery, isAddCartVisible },
    variantControl,
    avaliableControl,
    flyOutControl,
    currentBundle,
    productAvaliable,
  } = useProductDetail()

  const orderWatchProps = {} as OrderSwatchProps

  const productTitle = useMemo(() => {
    if (isAntiqueRug) {
      const includesAntiqueName = product.metafields.antiqueName.toLowerCase().includes('antique')

      if (includesAntiqueName) {
        return product.metafields.antiqueName
      } else {
        return `ANTIQUE ${product.metafields.antiqueName}`
      }
    }
    return product.title
  }, [product, isAntiqueRug])

  const currentPrice = currentBundle?.prices ? currentBundle?.prices : productPrice

  if (isUpholstery) {
    orderWatchProps.onCustomCart = () => {
      flyOutControl.setFlyOpen(true)
    }
  }

  return (
    <Wrapper>
      <FlyOutProvider
        open={flyOutControl.flyOpen}
        onOpen={() => flyOutControl.setFlyOpen(true)}
        onClose={() => flyOutControl.setFlyOpen(false)}
      >
        <ProductTitle>
          <H1>{productTitle}</H1>
        </ProductTitle>

        <ProductPriceContainer
          currentPrice={currentPrice}
          inStock={productAvaliable?.currentlyInStock && product.productType !== 'Antique Rugs'}
        />

        {isAntiqueRug && <AntiqueRugsInfos product={product} productVariant={productVariant} />}

        <Box sx={{ mb: [space.md2] }}>
          {!product.isCustomQuickship ? (
            <Parameters
              onChange={variantControl.onChangeParameter}
              title={productVariant.title}
              variant={productVariant}
              initialQuantity={1}
              customAttributes={avaliableControl.customAttributes}
              disabled={avaliableControl.currentlyNotInStock && !avaliableControl.preOrderAgreement}
              label={avaliableControl.currentlyNotInStock ? 'Pre-Order' : 'Add to Cart'}
              showAddToCart={variantControl.isNotSelectedVariant}
            />
          ) : (
            <Fragment>
              {isCustomQuickshipArt ? (
                <CustomQuickShipArt
                  product={product}
                  onChange={variantControl.onChangeParameter}
                  variant={productVariant}
                />
              ) : (
                <CustomQuickShip product={product} />
              )}
            </Fragment>
          )}
          {/* {variantControl.isNotSelectedVariant && (
          <Alert variant="info" mt={[space.gridGap1]}>
            This size is not available in this color. Please check other sizes.
          </Alert>
        )} */}
          <ProductLinks />
          {!productAvaliable?.currentlyInStock && product.productType !== 'Antique Rugs' && (
            <PreOrderNote />
          )}

          {/* {currentPrince.isSale && (
          <Box sx={{ mb: [space.sm1] }}>
            <Text>PRICE AS MARKED - FINAL SALE</Text>
          </Box>
        )} */}
          {isAddCartVisible && (
            <>
              <AddToCartButton
                title={product.title}
                variant={productVariant}
                initialQuantity={1}
                customAttributes={avaliableControl.customAttributes}
                sxProps={{
                  width: '100%',
                  height: '42px',
                }}
                disabled={
                  avaliableControl.currentlyNotInStock && !avaliableControl.preOrderAgreement
                }
                label={avaliableControl.currentlyNotInStock ? 'Pre-Order' : 'Add to Cart'}
              />
              <AffirmSnippet value={currentPrice.finalPrice} />
            </>
          )}
          <AddFavoriteButton product={product} productVariant={productVariant} pdp={true} />
          {/*
        // {productSwatches.length > 0 && !product.isCustomQuickship && (
        //   <OrderSwatch {...orderWatchProps} product={product} swatches={productSwatches} />
        // )} */}
          <InfoList>
            {/* {isAuth && isRugs && (
            <li>
              Complimentary ground shipping on all rugs
              <RiTruckLine />
            </li>
          )} */}
            {/* {productPrice.isTradeCustomer && <li>Courtesy to the Trade</li>} */}
          </InfoList>
          {isUpholstery && <FlyOut currentProduct={product} />}
        </Box>
      </FlyOutProvider>
    </Wrapper>
  )
}

export default ProductDetailPanel
