/** @jsx jsx */
import { jsx, Flex, Text, Box } from 'theme-ui'
import useFiles from '~/containers/product-detail/product-details-section/use-files'
import Files from '~/containers/product-detail/product-details/files'
import { ProductDetailsTab } from '~/containers/product-detail/product-details/types'
import { space } from '~/gatsby-plugin-theme-ui'

interface Metafields {
  [key: string]: string | undefined
}

const SpecsTab = ({ product, variant }: ProductDetailsTab) => {
  const variantSku = variant.sku

  const specsLines: string[] = []

  Object.keys(product.metafields).map(key => {
    if (key.includes('specs_')) {
      const metafield = product.metafields[key as keyof typeof product.metafields] as string
      if (metafield) {
        specsLines.push(metafield)
      }
    }

    return null
  })

  const productSpecs = product.metafields.productSpecsFabricContent
  const productSpecsPileHeight = product.metafields.productSpecsPileHeight
  const productSpecsSizes = product.metafields.productSpecsSizes
  const productSpecsCoo = product.metafields.productSpecsCoo
  const productSpecsGoodweave = product.metafields.productSpecsGoodweave
  const productSpecsAntiqueColor = product.metafields?.antiquesRugsColor?.join(' • ')
  const productSpecsExactSize = product.metafields?.exactSize
  const productSpecsOrigin = product.metafields?.antiqueStyle
  const selectedColor = variant?.selectedOptions?.find(variant => variant.name === 'Color')?.value

  const { files } = useFiles({
    product,
    selectedColor,
  })

  const antiqueRug = product?.productType === 'Antique Rugs' ? true : false

  const link = []
  if (antiqueRug) {
    link.push({ href: product.metafields?.antiquesPdfLink, title: 'TEARSHEET' })
  }

  return (
    <Box py={space.tabContent}>
      <Flex
        as="ul"
        sx={{
          fontSize: '16px',
          flexDirection: 'column',
        }}
      >
        {productSpecs && !specsLines.length && <li>{productSpecs}</li>}
        {specsLines.map(line => (
          <li key={line}>{line}</li>
        ))}
        {productSpecsPileHeight && <li>{productSpecsPileHeight}</li>}
        {productSpecsSizes && <li>{productSpecsSizes}</li>}
        {productSpecsCoo && <li>{productSpecsCoo}</li>}
        {productSpecsGoodweave && <li>{productSpecsGoodweave}</li>}
        {productSpecsExactSize && <li>{productSpecsExactSize}</li>}
        {productSpecsOrigin && <li>{productSpecsOrigin}</li>}
        {productSpecsAntiqueColor && <li>{productSpecsAntiqueColor}</li>}
      </Flex>
      {variantSku && (
        <Text
          sx={{
            fontSize: 16,
            mt: [space.gridGap1],
            ml: [20],
          }}
        >
          SKU: {variantSku}
        </Text>
      )}
      {files.length > 0 && (
        <Box
          sx={{
            fontSize: 16,
            mt: [space.md2, null, 25],
            pl: 14,
          }}
        >
          <Files links={!antiqueRug ? files : link} />
        </Box>
      )}
    </Box>
  )
}

export default SpecsTab
